<template>
  <nav class="menu padding-site">
    <div class="menu__linkblock menu__linkblock_nav">
      <slot name='home'></slot>
    </div>
    <div class="menu__linkblock menu__linkblock_nav">
      <slot name='men'></slot>
      <div class="menu__mega menu__mega_nav">
        <div class="menu__megacolumn">
          <ul class="menu__megabox">
            <li>
              <h3>Women</h3>
            </li>
            <li><a href="#">Dresses</a></li>
            <li><a href="#">Tops</a></li>
            <li><a href="#">Sweaters/Knits</a></li>
            <li><a href="#">Jackets/Coats</a></li>
            <li><a href="#">Blazers</a></li>
            <li><a href="#">Denim</a></li>
            <li><a href="#">Legging/Pants</a></li>
            <li><a href="#">Skirts/Shorts</a></li>
            <li><a href="#">Accessories</a></li>
          </ul>
        </div>
        <div class="menu__megacolumn">
          <ul class="menu__megabox">
            <h3>Women</h3>
            <li><a href="#">Dresses</a></li>
            <li><a href="#">Tops</a></li>
            <li><a href="#">Sweaters/Knits</a></li>
            <li><a href="#">Jackets/Coats</a></li>
          </ul>
          <ul class="menu__megabox">
            <h3>Women</h3>
            <li><a href="#">Dresses</a></li>
            <li><a href="#">Tops</a></li>
            <li><a href="#">Sweaters/Knits</a></li>
          </ul>
        </div>
        <div class="menu__megacolumn">
          <ul class="menu__megabox">
            <h3>Women</h3>
            <li><a href="#">Dresses</a></li>
            <li><a href="#">Tops</a></li>
            <li><a href="#">Sweaters/Knits</a></li>
            <li><a href="#">Jackets/Coats</a></li>
          </ul>
          <img src="../assets/imgs/megamenu banner.png" alt="megamenu banner" />
        </div>
      </div>
    </div>
    <div class="menu__linkblock menu__linkblock_nav">
      <slot name='women'></slot>
      <div class="menu__mega menu__mega_nav">
        <div class="menu__megacolumn">
          <ul class="menu__megabox">
            <li>
              <h3>Women</h3>
            </li>
            <li><a href="#">Dresses</a></li>
            <li><a href="#">Tops</a></li>
            <li><a href="#">Sweaters/Knits</a></li>
            <li><a href="#">Jackets/Coats</a></li>
            <li><a href="#">Blazers</a></li>
            <li><a href="#">Denim</a></li>
            <li><a href="#">Legging/Pants</a></li>
            <li><a href="#">Skirts/Shorts</a></li>
            <li><a href="#">Accessories</a></li>
          </ul>
        </div>
        <div class="menu__megacolumn">
          <ul class="menu__megabox">
            <h3>Women</h3>
            <li><a href="#">Dresses</a></li>
            <li><a href="#">Tops</a></li>
            <li><a href="#">Sweaters/Knits</a></li>
            <li><a href="#">Jackets/Coats</a></li>
          </ul>
          <ul class="menu__megabox">
            <h3>Women</h3>
            <li><a href="#">Dresses</a></li>
            <li><a href="#">Tops</a></li>
            <li><a href="#">Sweaters/Knits</a></li>
          </ul>
        </div>
        <div class="menu__megacolumn">
          <ul class="menu__megabox">
            <h3>Women</h3>
            <li><a href="#">Dresses</a></li>
            <li><a href="#">Tops</a></li>
            <li><a href="#">Sweaters/Knits</a></li>
            <li><a href="#">Jackets/Coats</a></li>
          </ul>
          <img src="../assets/imgs/megamenu banner.png" alt="megamenu banner" />
        </div>
      </div>
    </div>
    <div class="menu__linkblock menu__linkblock_nav">
      <slot name='kids'></slot>
      <div class="menu__mega menu__mega_nav menu__mega_middle">
        <div class="menu__megacolumn">
          <ul class="menu__megabox">
            <li>
              <h3>Women</h3>
            </li>
            <li><a href="#">Dresses</a></li>
            <li><a href="#">Tops</a></li>
            <li><a href="#">Sweaters/Knits</a></li>
            <li><a href="#">Jackets/Coats</a></li>
            <li><a href="#">Blazers</a></li>
            <li><a href="#">Denim</a></li>
            <li><a href="#">Legging/Pants</a></li>
            <li><a href="#">Skirts/Shorts</a></li>
            <li><a href="#">Accessories</a></li>
          </ul>
        </div>
        <div class="menu__megacolumn">
          <ul class="menu__megabox">
            <h3>Women</h3>
            <li><a href="#">Dresses</a></li>
            <li><a href="#">Tops</a></li>
            <li><a href="#">Sweaters/Knits</a></li>
            <li><a href="#">Jackets/Coats</a></li>
          </ul>
          <ul class="menu__megabox">
            <h3>Women</h3>
            <li><a href="#">Dresses</a></li>
            <li><a href="#">Tops</a></li>
            <li><a href="#">Sweaters/Knits</a></li>
          </ul>
        </div>
        <div class="menu__megacolumn">
          <ul class="menu__megabox">
            <h3>Women</h3>
            <li><a href="#">Dresses</a></li>
            <li><a href="#">Tops</a></li>
            <li><a href="#">Sweaters/Knits</a></li>
            <li><a href="#">Jackets/Coats</a></li>
          </ul>
          <img src="../assets/imgs/megamenu banner.png" alt="megamenu banner" />
        </div>
      </div>
    </div>
    <div class="menu__linkblock menu__linkblock_nav">
      <slot name='accessories'></slot>
      <div class="menu__mega menu__mega_nav menu__mega_middle">
        <div class="menu__megacolumn">
          <ul class="menu__megabox">
            <li>
              <h3>Women</h3>
            </li>
            <li><a href="#">Dresses</a></li>
            <li><a href="#">Tops</a></li>
            <li><a href="#">Sweaters/Knits</a></li>
            <li><a href="#">Jackets/Coats</a></li>
            <li><a href="#">Blazers</a></li>
            <li><a href="#">Denim</a></li>
            <li><a href="#">Legging/Pants</a></li>
            <li><a href="#">Skirts/Shorts</a></li>
            <li><a href="#">Accessories</a></li>
          </ul>
        </div>
        <div class="menu__megacolumn">
          <ul class="menu__megabox">
            <h3>Women</h3>
            <li><a href="#">Dresses</a></li>
            <li><a href="#">Tops</a></li>
            <li><a href="#">Sweaters/Knits</a></li>
            <li><a href="#">Jackets/Coats</a></li>
          </ul>
          <ul class="menu__megabox">
            <h3>Women</h3>
            <li><a href="#">Dresses</a></li>
            <li><a href="#">Tops</a></li>
            <li><a href="#">Sweaters/Knits</a></li>
          </ul>
        </div>
        <div class="menu__megacolumn">
          <ul class="menu__megabox">
            <h3>Women</h3>
            <li><a href="#">Dresses</a></li>
            <li><a href="#">Tops</a></li>
            <li><a href="#">Sweaters/Knits</a></li>
            <li><a href="#">Jackets/Coats</a></li>
          </ul>
          <img src="../assets/imgs/megamenu banner.png" alt="megamenu banner" />
        </div>
      </div>
    </div>
    <div class="menu__linkblock menu__linkblock_nav">
      <slot name='featured'></slot>
      <div class="menu__mega menu__mega_nav menu__mega_last">
        <div class="menu__megacolumn">
          <ul class="menu__megabox">
            <li>
              <h3>Women</h3>
            </li>
            <li><a href="#">Dresses</a></li>
            <li><a href="#">Tops</a></li>
            <li><a href="#">Sweaters/Knits</a></li>
            <li><a href="#">Jackets/Coats</a></li>
            <li><a href="#">Blazers</a></li>
            <li><a href="#">Denim</a></li>
            <li><a href="#">Legging/Pants</a></li>
            <li><a href="#">Skirts/Shorts</a></li>
            <li><a href="#">Accessories</a></li>
          </ul>
        </div>
        <div class="menu__megacolumn">
          <ul class="menu__megabox">
            <h3>Women</h3>
            <li><a href="#">Dresses</a></li>
            <li><a href="#">Tops</a></li>
            <li><a href="#">Sweaters/Knits</a></li>
            <li><a href="#">Jackets/Coats</a></li>
          </ul>
          <ul class="menu__megabox">
            <h3>Women</h3>
            <li><a href="#">Dresses</a></li>
            <li><a href="#">Tops</a></li>
            <li><a href="#">Sweaters/Knits</a></li>
          </ul>
        </div>
        <div class="menu__megacolumn">
          <ul class="menu__megabox">
            <h3>Women</h3>
            <li><a href="#">Dresses</a></li>
            <li><a href="#">Tops</a></li>
            <li><a href="#">Sweaters/Knits</a></li>
            <li><a href="#">Jackets/Coats</a></li>
          </ul>
          <img src="../assets/imgs/megamenu banner.png" alt="megamenu banner" />
        </div>
      </div>
    </div>
    <div class="menu__linkblock menu__linkblock_nav">
      <slot name='hotDeals'></slot>
      <div class="menu__mega menu__mega_nav menu__mega_last">
        <div class="menu__megacolumn">
          <ul class="menu__megabox">
            <li>
              <h3>Women</h3>
            </li>
            <li><a href="#">Dresses</a></li>
            <li><a href="#">Tops</a></li>
            <li><a href="#">Sweaters/Knits</a></li>
            <li><a href="#">Jackets/Coats</a></li>
            <li><a href="#">Blazers</a></li>
            <li><a href="#">Denim</a></li>
            <li><a href="#">Legging/Pants</a></li>
            <li><a href="#">Skirts/Shorts</a></li>
            <li><a href="#">Accessories</a></li>
          </ul>
        </div>
        <div class="menu__megacolumn">
          <ul class="menu__megabox">
            <h3>Women</h3>
            <li><a href="#">Dresses</a></li>
            <li><a href="#">Tops</a></li>
            <li><a href="#">Sweaters/Knits</a></li>
            <li><a href="#">Jackets/Coats</a></li>
          </ul>
          <ul class="menu__megabox">
            <h3>Women</h3>
            <li><a href="#">Dresses</a></li>
            <li><a href="#">Tops</a></li>
            <li><a href="#">Sweaters/Knits</a></li>
          </ul>
        </div>
        <div class="menu__megacolumn">
          <ul class="menu__megabox">
            <h3>Women</h3>
            <li><a href="#">Dresses</a></li>
            <li><a href="#">Tops</a></li>
            <li><a href="#">Sweaters/Knits</a></li>
            <li><a href="#">Jackets/Coats</a></li>
          </ul>
          <img src="../assets/imgs/megamenu banner.png" alt="megamenu banner" />
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'naviBar',
};
</script>

<style lang="scss">

@import '../layout/styles/_variables.scss';
.menu {
  display: flex;
  justify-content: center;

  &__linkblock {
    position: relative;
    height: 51px;

    &:hover .menu__mega {
      display: flex;
    }
  }

  &__link {    
    display: block;
    padding: 20px 20px 15px;
    font-size: 14px;
    font-weight: bold;
    color: #222222;
    text-transform: uppercase;

    &:hover {
      border-bottom: 3px solid $colorOfElements;
    }
  }

  &__mega {
    padding: 20px 25px 20px 3px;
    width: fit-content;
    position: absolute;
    display: flex;
    z-index: 1;
    background-color: #fff;
    border-radius: 5px;

    &_middle {
      left: -200px;
    }

    &_last {
      right: 0;
    }

    &_nav {
      display: none;
    }
  }

  &__megacolumn {
    width: 251px;
    margin-right: 30px;
    position: relative;

    &:last-child {
      margin-right: 0;
    }

    img {
      position: absolute;
      bottom: 7px;
    }
  }

  &__megabox {
    margin-bottom: 24px;
    margin-left: 17px;

    &:last-child {
      margin-bottom: 0;
    }

    h3 {
      padding-bottom: 8px;
      margin-bottom: 8px;
      font-size: 14px;
      color: #232323;
      text-transform: uppercase;
      border-bottom: 1px solid #e8e8e8;
    }

    a {
      display: block;
      font-size: 14px;
      color: #646464;
      line-height: 28px;
      &:hover {
        color: $colorOfElements;
      }
    }
  }
}
</style>
